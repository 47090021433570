import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function Message({ key, text, isUser, answer }) {
  console.log("key", key)
  const [showText, setShowText] = useState(false);

  const handleToggle = () => {
    setShowText(!showText);
  };

  if (!answer && !isUser) {
    return (
      <div className="message bot">
        <img src="icon1.png" alt="Bot" className="bot-image" />
        <div className="message-content">
          <p>{text}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`message ${isUser ? 'user' : 'bot'}`}>
      <div className="icon-wrapper">
        <img src={isUser ? "icon2.png" : "icon1.png"} className="icon" />
      </div>
      <div className="message-content">
        {answer && <p><b>{answer}</b></p>}
        <br />
        {isUser ? <p>{text}</p> : ''}
        {showText ? (
          <p>{text}</p>
        ) : (
          <p className="see-more" onClick={handleToggle}>
            {!isUser ? 'See more...' : ''}
          </p>
        )}
      </div>
    </div>
  );
}

function App() {
  const [messages, setMessages] = useState([{ text: 'Hello..! Welcome to SwinFAQBot, I am your Swinburne virtual assistant, here to help with any questions you have. How can I assist you today?', isUser: false }]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const messageContainerRef = useRef(null);

  const sendMessage = async () => {
    if (!inputText.trim()) return;

    const newMessage = { text: inputText, isUser: true };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputText('');
    setLoading(true);

    try {
      const response = await fetch('http://127.0.0.1:5000/api/get_context', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: inputText }),
      });

      const data = await response.json();
      console.log('data: ', data)
      setTimeout(() => {
        if (response.ok) {
          const botMessage = { text: data.Context, isUser: false, answer: data.Answer };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        } else {
          const botMessage = { text: data.error || 'An error occurred', isUser: false };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }
        setLoading(false);
      }, 2000);
    } catch (err) {
      const botMessage = { text: 'An error occurred: ' + err.message, isUser: false };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>SwinFAQBot</h1>
        <div className="chat-container">
          <div className="message-container" ref={messageContainerRef}>
            {messages.map((message, index) => (
              <Message key={index} text={message.text} isUser={message.isUser} answer={message.answer} />
            ))}
          </div>
          <div className="input-container">
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Ask a question..."
              className="input"
              disabled={loading}
            />
            <button type="button" onClick={sendMessage} className="button" disabled={loading}>
              {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
